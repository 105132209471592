import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { siteMetadata } from "../../../gatsby-config";

const query = graphql`
    {
        strapiFooter {
            footerImageGroup {
                id
                text
                img {
                    url
                }
            }
            footerlinkGroups {
                id
                title
                footerlinks {
                    id
                    linktext
                    url
                }
            }
            footerButtonGroup {
                beforeText
                id
                footerButton {
                    ButtonRoute
                    ButtonText
                    id
                }
            }
            phone
            footerSocialLinks {
                id
                socialLink {
                    icon {
                        url
                    }
                    id
                    url
                }
            }
            legalFooter {
                linkText
                url
            }
        }
    }
`;

export default function Footer() {
    const data = useStaticQuery(query);
    return (
        <div className="footer-container">
            <footer className="site-footer pb-15">
                <Container>
                    <Row>
                        {data.strapiFooter.footerlinkGroups &&
                            data.strapiFooter.footerlinkGroups.map((linkgroup) => {
                                return (
                                    <Col md={2} sm={3} xs={6} key={linkgroup.id}>
                                        <h4>{linkgroup.title}</h4>
                                        <ul className="list-unstyled m-0">
                                            {linkgroup.footerlinks &&
                                                linkgroup.footerlinks.map((footerlink) => {
                                                    return (
                                                        <li key={footerlink.id}>
                                                            <a href={footerlink.url}>{footerlink.linktext}</a>
                                                        </li>
                                                    );
                                                })}
                                        </ul>
                                    </Col>
                                );
                            })}

                        <Col md={2} sm={3} xs={6}>
                            <div className="certifications mb-20">
                                {data.strapiFooter.footerImageGroup &&
                                    data.strapiFooter.footerImageGroup.map((image) => {
                                        return (
                                            <div key={image.id}>
                                                <p>
                                                    {image.img && <img loading="lazy" className="img-fluid" srcSet={siteMetadata.apiURL + image.img.url} alt={image.text} />}
                                                </p>
                                                <p className="small">{image.text}</p>
                                                <br />
                                            </div>
                                        );
                                    })}
                            </div>
                        </Col>

                        <Col md={4} xs={12}>
                            <div className="footer-cta">
                                {data.strapiFooter.footerButtonGroup &&
                                    data.strapiFooter.footerButtonGroup.map((buttonItem) => {
                                        return (
                                            <div key={buttonItem.id}>
                                                <h4>{buttonItem.beforeText}</h4>
                                                <p>
                                                    <a href={buttonItem.footerButton.ButtonRoute} className="open-demo-modal btn btn-medium btn-green btn-left">
                                                        {buttonItem.footerButton.ButtonText}
                                                    </a>
                                                </p>
                                            </div>
                                        );
                                    })}
                                <h4 className="mt-20 mb-30">
                                    Call Us: <span className="fw-normal">{data.strapiFooter.phone}</span>
                                </h4>
                            </div>
                        </Col>

                        <Row>
                            <Col md={12} className="social-links">
                                Follow or connect with us:
                                {data.strapiFooter.footerSocialLinks &&
                                    data.strapiFooter.footerSocialLinks.socialLink.map((sLink) => {
                                        return (
                                            <a href={sLink.url} target="_blank" aria-label={"social-link" + sLink.id} rel="nofollow noreferrer" key={sLink.id}>
                                                <img loading="lazy" src={siteMetadata.apiURL + sLink.icon.url} width="36" height="36" alt="" />
                                            </a>
                                        );
                                    })}
                            </Col>
                        </Row>
                    </Row>
                </Container>
            </footer>

            {/* <LogoCarousel/> */}

            <Container fluid className="legal-footer text-center">
                {data.strapiFooter.legalFooter &&
                    data.strapiFooter.legalFooter.map((link) => {
                        return (
                            <a href={link.url} target="_self" rel="nofollow" key={link.id}>
                                {link.linkText}
                            </a>
                        );
                    })}
            </Container>
        </div>
    );
}
