import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Nav, Navbar, NavDropdown } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import { siteMetadata } from "../../../gatsby-config"

const query = graphql`
    {
        strapiNavbar {
            navbargroup {
                groupDescription
                groupName
                id
                route
                navbarlink {
                    title
                    description
                    route
                    image {
                        url
                    }
                }
            }
            simplenavbarlink {
                id
                route
                title
            }
        }
    }
`

export default function NavBar() {
    const data = useStaticQuery(query)

    const [show, setShow] = useState(false)

    return (
        <div className="main-nav">
            <Container>
                <Navbar className="navbar-inverse p-0 justify-content-end justify-content-lg-start" expand="lg">
                    <div className="navbar-header">
                        <Navbar.Toggle type="button" aria-controls="#myNavbar">
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </Navbar.Toggle>
                    </div>
                    <Navbar.Collapse id="myNavbar">
                        <Nav className="d-none d-lg-flex nav">
                            {data.strapiNavbar.navbargroup.map((group) => {
                                return (
                                    <NavDropdown
                                        className="dropdown menu-large"
                                        title={group.groupName}
                                        key={group.id}
                                        id={"collasible-nav-dropdown-" + group.id}
                                        show={show === group.groupName}
                                        onMouseEnter={() => setShow(group.groupName)}
                                        onMouseLeave={() => setShow(false)}
                                        onToggle={() => {
                                            window.location.href = group.route
                                        }}>
                                        <div className="row dropdown-item bg-white">
                                            <Row>
                                                <Col lg={12}>
                                                    <h3>{group.groupName}</h3>
                                                    <p>{group.groupDescription}</p>
                                                </Col>
                                                {group.navbarlink.map((navbarlink) => {
                                                    return (
                                                        <Col lg={4} sm={6} key={navbarlink.id}>
                                                            <a href={navbarlink.route}>
                                                                <div className="link-info">
                                                                    <p>
                                                                        {navbarlink.image && navbarlink.image[0] && (
                                                                            <img loading="lazy" className="img-fluid" srcSet={siteMetadata.apiURL + navbarlink.image[0].url} alt="" />
                                                                        )}
                                                                    </p>
                                                                    <h4>{navbarlink.title}</h4>
                                                                    <p>{navbarlink.description}</p>
                                                                </div>
                                                            </a>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </div>
                                    </NavDropdown>
                                )
                            })}
                            {data.strapiNavbar.simplenavbarlink.map((link) => {
                                return (
                                    <div className="nav-item" key={link.id}>
                                        <a className="" href={link.route}>
                                            {link.title}
                                        </a>
                                    </div>
                                )
                            })}
                        </Nav>

                        {/* MOBILE VERSION */}
                        <Nav className="d-lg-none d-block nav navbar-nav mobile-nav">
                            {data.strapiNavbar.navbargroup.map((group) => {
                                return (
                                    <NavDropdown key={group.id} title={group.groupName} id="collasible-nav-dropdown">
                                        {group.navbarlink.map((navbarlink) => {
                                            return <NavDropdown.Item key={navbarlink.id} href={navbarlink.route}>{navbarlink.title}</NavDropdown.Item>
                                        })}
                                    </NavDropdown>
                                )
                            })}

                            {data.strapiNavbar.simplenavbarlink.map((link) => {
                                return (
                                    <div key={link.id} className="nav-item single-item">
                                        <a href={link.route}>{link.title}</a>
                                    </div>
                                )
                            })}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </div>
    )
}
