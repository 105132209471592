import React from 'react';
import { Container } from 'react-bootstrap';
import { graphql, useStaticQuery } from 'gatsby';
import { siteMetadata } from '../../../gatsby-config';

import NavBar from './Navbar';

import { Helmet } from "react-helmet"

const query = graphql`
  {
    strapiHeader {
      contactus
      contactUsLink
      phone
      logo {
        url
      }
    }
  }
`;

export default function Header(props) {
    const data = useStaticQuery(query);

    return (
        <div className='header-wrap'>
            <Helmet htmlAttributes={{ lang: 'en', }}>
                <title>{props.siteMetadata.metaTitle}</title>
                <meta charset="utf-8" />
                <meta name="description" content={props.siteMetadata.metaDescription} />
                <link rel="shortcut icon" href="/favicon.ico" />
                <meta property="og:url" content={props.siteMetadata.route} />
                <meta property="og:title" content={props.siteMetadata.metaTitle} />
                <meta property="og:description" content={props.siteMetadata.metaDescription} />
                <meta property="og:image" content="https://www.changefirst.com/images/changefirst-logo-og.png" />
                <meta property="og:type" content="website" />
                <meta name="robots" content={(props.siteMetadata.noIndex ? "" : "index,") + (props.siteMetadata.nofollow ? "" : "follow")}/>
                <link rel="canonical" href={props.siteMetadata.canonicalURL} />
            </Helmet>
            <Container fluid>
                <div className='header main-header clearfix'>
                    <nav className='contacts'>
                        <ul className='list-inline pull-right'>
                        <li>
                            <a href={data.strapiHeader.contactUsLink} id='Contact_Us' className='nav-item nav-link btn btn-sm btn-primary'>
                            {data.strapiHeader.contactus}
                            </a>
                        </li>
                        <li>
                            <a className='nav-item nav-link tel-no' href='tel:+44-0-1444-450777'>
                            {data.strapiHeader.phone}
                            </a>
                        </li>
                        </ul>
                    </nav>
                    <div className='logo'>
                        <a href='/'>
                            {data.strapiHeader.logo && data.strapiHeader.logo[0] && <img loading='lazy' width="100%" height="100%" className='new-logo' src={siteMetadata.apiURL + data.strapiHeader.logo[0].url} alt='Change First'/>}
                        </a>
                    </div>
                </div>
            </Container>
            <NavBar />
        </div>
    );
}
